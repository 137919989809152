.page2 {
    margin-top: 20px;
    position: relative;
  }
  
  .page2.one-reg2 {
    margin-bottom: 150px;
  }
  
  /* .form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ebebeb;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 15px;
    padding: 50px 40px 40px;
  } */
  
  /* .form-container.reg {
    margin: 260px 0;
  } */
  
  .input-section {
    display: flex;
    flex-direction: column;
  }
  
  
  .login-btn {
    padding: 15px;
    margin: 10px 0;
    color: #fff;
    border: none;
    background-color: #003898;
    border-radius: 15px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  input {
    font-size: 1rem;
  }
  
  .password-container {
    position: relative;
    width: 100%;
  }
  select{
    width: 100%;
  }
  .eye {
    cursor: pointer;
    position: absolute;
    top: 32%;
    left: 90%;
    width: 24px;
  }
  .eye2 {
    cursor: pointer;
    position: absolute;
    top: 32%;
    left: 45%;
    width: 24px;
  }
  @media only screen and (max-width: 926px) {
    #password{
      width: 90%;
    }
    .eye2 {
      cursor: pointer;
      position: absolute;
      top: 32%;
      left: 90%;
      width: 24px;
    }
  }
  .eye.profile{
    top: 20%;
  }
  
  .eye.sup{
    top: 25%;
    left: 80%;
  }
  
  
  .reg-input-section {
    display: flex;
  }
  
  .reg-form-input {
    padding: 15px;
    min-width: 25vw;
    border: none;
    border-radius: 15px;
    background-color: #fff;
  }
  
  .reg-block {
    margin: 10px 10px;
    flex-basis: 0;
    flex-grow: 1;
  }
  
  .reg-block.cntr {
    display: grid;
  }
  
  .post-fail {
    color: #b83c37;
    background-color: #ecc8c5;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #b83c37;
  }
  
  .post-success {
    padding: 10px;
    border-radius: 3px;
    color: #597151;
    background-color: #ddf3d5;
    border: 1px solid #597151;
  }
  
  .basic:before {
    content: "";
    display: block;
    height: 20px;
    width: 20px;
    -webkit-animation: spin 0.5s infinite linear;
    animation: spin 0.5s infinite linear;
    border: 2px #e7e7e7 solid;
    border-left-color: #003898;
    border-radius: 100%;
  }
  
  .basic.big::before {
    height: 90px;
    width: 90px;
    border: 4px #e7e7e7 solid;
    border-left-color: #003898;
  }
  
  .center-load{
    display: grid;
    place-content: center;
  }
  
  .error-list{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media (max-width: 924px) {
    .page2.one-reg2 {
      margin-bottom: 280px;
    }
  
    .form-container.reg {
      margin: 50px 20px;
    }
  
    .eye {
      left: 85%;
    }
  
    .reg-input-section {
      flex-direction: column;
    }
  
    .reg-block {
      margin: 10px 0;
      display: flex;
      flex-direction: column;
    }
  
    .reg-form-input {
      min-width: 60vw;
    }
  }
  
  @media (max-width: 650px) {
    
    .reg-form-input {
      min-width: 62vw;
    }
  }