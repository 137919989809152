.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #003898;
  width: 42px;
  height: 100%;
  padding: 6px 10px;
  transition: all 0.4s ease-in;
  z-index: 99;
}

.sidebar.co {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 44px;
}

.sidebar.co::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.sidebar.co::-webkit-scrollbar-track {
  background-color: #1058d3;
  border-radius: 10px;
}

.sidebar.co::-webkit-scrollbar-thumb {
  background-color: rgba(250, 242, 242, 0.35);
  border-radius: 10px;
}

.sidebar.active {
  width: 270px;
}

.sidebar .logo-content .logo {
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
  opacity: 0;
  transition: all 0.5s ease-in;
}

.sidebar.active .logo-content .logo {
  opacity: 1;
}

.logo-img {
  width: 43px;
  margin-right: 10px;
  object-fit: cover;
}

.logo-img-name {
  width: 130px;
  object-fit: cover;
}

.sidebar-burger {
  background: transparent;
  border: none;
  cursor: pointer;
}

.sidebar-btn.btn-h,
.sidebar-btn.btn-x {
  position: absolute;
  top: 20px;
  left: 50%;
  transition: all 0.4s ease-in;
  transform: translateX(-50%);
}

.sidebar.active .sidebar-btn.btn-h,
.sidebar.active .sidebar-btn.btn-x {
  left: 90%;
}

.sidebar-btn.btn-x,
.sidebar-btn.btn-h.active {
  opacity: 0;
}

.sidebar-btn.btn-x.active {
  opacity: 1;
}

.sidebar ul {
  margin-top: 20px;
}

.sidebar ul li {
  position: relative;
  list-style: none;
  height: 80px;
  width: 100%;
  margin: 0 0 0 -38px;
}

/* .sidebar ul li .tooltip {
  position: absolute;
  left: 200px;
  top: 50%;
  transform: translateY(-50%);
  height: 32px;
  width: 100px;
  background-color: beige;
  z-index: 1000;
} */

.sidebar .logout a,
.sidebar ul li a,
.sidebar .logout-co a {
  position: relative;
  color: #f7f7f7;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 500;
  transition: 0.2s ease-in;
  padding: 5px;
  border-radius: 8px;
  -webkit-tap-highlight-color: transparent;
}

.sidebar ul li a:hover,
.active-link {
  background-color: #fff;
  width: 24px;
  color: #000;
}

.sidebar ul li:hover img {
  filter: brightness(0) saturate(100%) invert(14%) sepia(52%) saturate(6346%)
    hue-rotate(216deg) brightness(96%) contrast(95%);
}

.active-link img {
  filter: brightness(0) saturate(100%) invert(14%) sepia(52%) saturate(6346%)
    hue-rotate(216deg) brightness(96%) contrast(95%);
}

.sidebar.active ul li a:hover,
.sidebar.active .active-link {
  width: auto;
  color: #000;
}

.link-name {
  margin-left: 6px;
}

.sidebar .link-name {
  transition: all 0.1s ease-in;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
}

.sidebar.active .link-name {
  opacity: 1;
  visibility: visible;
}

.sidebar-list {
  position: relative;
  display: flex;
  flex-direction: column;
}

.sidebar .logout {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  padding: 10px 0 20px;
  background-color: #0646b6;
  cursor: pointer;
}

.sidebar .logout-co {
  position: relative;
  width: 100%;
  margin-left: -10px;
  padding: 10px 10px 20px;
  background-color: #0646b6;
  cursor: pointer;
}

.sidebar .logout img {
  margin-left: 14px;
}
