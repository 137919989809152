.nav-menu {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0.8rem 0;
  width: 100%;
  background-color: #f7f7f7;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.nav-list-container {
  margin: 0;
  padding: 0 3rem;
  display: flex;
  list-style: none;
  align-items: center;
}

.nav-item:first-child {
  padding-top: 0.3rem;
  margin-right: auto;
}

.menu-bar {
  display: flex;
}

.nav-img {
  display: none;
}

.nav-image {
  height: 30px;
  width: 280px;
}

.nav-link {
  position: relative;
  margin-right: 1rem;
  padding: 1rem 1rem 0.4rem 1rem;
  text-decoration: none;
  color: #004282;
  font-size: 1.3rem;
  font-weight: 600;
}

.nav-link::after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 0.15rem;
  left: 0;
  bottom: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
  background-color: #b98d3b;
}

.nav-link__active {
  position: absolute;
  width: 100%;
  height: 0.15rem;
  left: 0;
  bottom: 0;
  background-color: #b98d3b;
}

.nav-link:hover::after {
  transform: scale(1, 1);
}

.hamburger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
}

.hamburger:focus {
  outline: none;
}

.menu-burger {
  width: 2rem;
  height: 0.25rem;
  border-radius: 5px;
  background: #004282;
  transition: all 0.5s ease-in-out;
}

.menu-burger::before,
.menu-burger::after {
  content: " ";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 2rem;
  height: 0.25rem;
  border-radius: 5px;
  background: #004282;
  transition: all 0.5s ease-in-out;
}

.menu-burger::before {
  transform: translateY(-0.5rem);
}

.menu-burger::after {
  transform: translateY(0.5rem);
}

.menu-btn.open .menu-burger {
  transform: translateX(-50px);
  background: transparent;
}

.menu-btn.open .menu-burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.open .menu-burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

@media (max-width: 920px) {
  .hamburger {
    position: relative;
    z-index: 100;
    display: block;
    padding: 5px;
    margin-left: auto;
  }

  .nav-img {
    display: block;
    position: relative;
    left: -10px;
    height: 30px;
    width: 250px;
  }

  .nav-list-container {
    flex-direction: column;
    top: -100vh;
    left: 0;
    padding: 0;
    position: fixed;
    background: #f7f7f7;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition: transform 0.8s;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }

  .nav-list-container.show {
    transition: transform 0.8s;
    transform: translateY(100vh);
  }

  .none {
    display: none;
  }

  .nav-item {
    line-height: 4.5rem;
  }
}
