.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-table100 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin: 0 100px;
  /* justify-content: center; */
  flex-wrap: wrap;
  padding: 33px 10px;
  position: relative;
}

.wrap-table100 {
  width: 1170px;
}

table {
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

table * {
  position: relative;
}

table td,
table th {
  padding-left: 8px;
}

table thead tr {
  height: 60px;
  background: #003898;
}

table tbody tr {
  height: 50px;
}

table tbody tr:last-child {
  border: 0;
}

table td,
table th {
  text-align: left;
}

table td.l,
table th.l {
  text-align: right;
}

table td.c,
table th.c {
  text-align: center;
}

table td.r,
table th.r {
  text-align: center;
}

.table100-head th {
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset;
}

tbody tr:nth-child(even) {
  background-color: #f7f7f7;
}

tbody tr {
  font-size: 15px;
  color: #808080;
  line-height: 1.2;
  font-weight: unset;
}

tbody tr:hover {
  color: #555555;
  cursor: pointer;
}

.column4,
.column5,
.column6,
.column1,
.column2,
.column3 {
  padding: 10px;
}

.column1 {
  width: 100px;
  padding-left: 40px;
}

.column2 {
  width: 330px;
}

.column3 {
  width: 100px;
}

.column4 {
  width: 245px;
}

.column5 {
  width: 170px;
}

.column6 {
  width: 222px;
  padding-right: 62px;
}

.column1.def {
  width: 180px;
  padding-left: unset;
}

.column2.def {
  width: 180px;
}

.column5.def {
  width: 190px;
}

.column6.def {
  width: 222px;
  padding-right: unset;
}

.table-btn-container {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.table-btn {
  text-decoration: none;
  color: #003898;
}

.table-btn.greyed {
  color: #555555;
}

