@media screen and (max-width: 992px) {
  table {
    display: block;
  }

  table > *,
  table tr,
  table td,
  table th {
    display: block;
  }

  table thead {
    display: none;
  }

  table tbody tr {
    height: auto;
    padding: 57px 0;
    overflow-x: scroll;
  }

  table tbody tr td {
    padding-left: 50% !important;
    margin-bottom: 24px;
  }

  table tbody tr td:last-child {
    margin-bottom: 0;
  }

  table tbody tr td:before {
    font-size: 14px;
    color: #999999;
    line-height: 1.2;
    font-weight: unset;
    position: absolute;
    width: 40%;
    left: 30px;
    top: 0;
  }

  table tbody tr td:nth-child(1):before {
    content: "Form Type";
  }

  table tbody tr td:nth-child(2):before {
    content: "Form Description";
  }

  table tbody tr td:nth-child(3):before {
    content: "Form Document";
  }

  table tbody tr td:nth-child(4):before {
    content: "Email";
  }

  table tbody tr td:nth-child(5):before {
    content: "Phone Number";
  }

  table tbody tr td:nth-child(6):before {
    content: "Postal Address";
  }
  /* --------------------------------------------- */

  table.inspection-table tbody tr td:nth-child(1):before {
    content: "Student Name";
  }

  table.inspection-table tbody tr td:nth-child(2):before {
    content: "Phone Number";
  }

  table.inspection-table tbody tr td:nth-child(3):before {
    content: "Email";
  }

  table.inspection-table tbody tr td:nth-child(4):before {
    content: "Company Name";
  }

  table.inspection-table tbody tr td:nth-child(5):before {
    content: "Address";
  }

  table.inspection-table tbody tr td:nth-child(6):before {
    content: "State";
  }

  table.inspection-table tbody tr td:nth-child(7):before {
    content: "Set Ins. Date";
  }

  /* --------------------------------------------- */


  table.inspection-table.grade tbody tr td:nth-child(1):before {
    content: "Student Name";
  }

  table.inspection-table.grade tbody tr td:nth-child(2):before {
    content: "Matric Number";
  }

  table.inspection-table.grade tbody tr td:nth-child(3):before {
    content: "Course";
  }

  table.inspection-table.grade tbody tr td:nth-child(4):before {
    content: "Inspection Grade";
  }

  table.inspection-table.grade.def tbody tr td:nth-child(4):before {
    content: "Defence Grade";
  }

  table.inspection-table.grade tbody tr td:nth-child(5):before {
    content: "";
  }

  /* --------------------------------------------- */

  table.co-inspection-table tbody tr td:nth-child(1):before {
    content: "Student Name";
  }

  table.co-inspection-table tbody tr td:nth-child(2):before {
    content: "Matric Number";
  }

  table.co-inspection-table tbody tr td:nth-child(3):before {
    content: "Course";
  }

  table.co-inspection-table tbody tr td:nth-child(4):before {
    content: "student phone number";
  }

  table.co-inspection-table tbody tr td:nth-child(5):before {
    content: "Company Name";
  }
  table.co-inspection-table tbody tr td:nth-child(6):before {
    content: "State";
  }
  table.co-inspection-table tbody tr td:nth-child(7):before {
    content: "LGA";
  }
  table.co-inspection-table tbody tr td:nth-child(8):before {
    content: "Address";
  }
  table.co-inspection-table tbody tr td:nth-child(9):before {
    content: "Assigned Superisor";
  }

  table.co-inspection-table tbody tr td:nth-child(10):before {
    content: "Select Inspector";
  }

  /* --------------------------------------------- */

  table.defence-table tbody tr td:nth-child(1):before {
    content: "Student Name";
  }

  table.defence-table tbody tr td:nth-child(2):before {
    content: "Matric Number";
  }

  table.defence-table tbody tr td:nth-child(3):before {
    content: "Course";
  }

  table.defence-table tbody tr td:nth-child(4):before {
    content: "Company Name";
  }

  table.defence-table tbody tr td:nth-child(5):before {
    content: "Date";
  }

  table.defence-table tbody tr td:nth-child(6):before {
    content: "";
  }

  /* --------------------------------------------- */

  table.co.defence-table tbody tr td:nth-child(4):before {
    content: "Email";
  }

  table.co.defence-table tbody tr td:nth-child(5):before {
    content: "Level";
  }

  table.co.defence-table tbody tr td:nth-child(6):before {
    content: "Select Supervisor";
  }

  /* --------------------------------------------- */

  table.reg-supervisors tbody tr td:nth-child(1):before {
    content: "Supervisor Name";
  }

  table.reg-supervisors tbody tr td:nth-child(2):before {
    content: "Phone Number";
  }

  table.reg-supervisors tbody tr td:nth-child(3):before {
    content: "Email";
  }
  table.reg-supervisors tbody tr td:nth-child(4):before {
    content: "Number of students assigned";
  }

  /* --------------------------------------------- */

  table.co-students tbody tr td:nth-child(1):before {
    content: "Student Name";
  }

  table.co-students tbody tr td:nth-child(2):before {
    content: "Matric Number";
  }

  table.co-students tbody tr td:nth-child(3):before {
    content: "Course";
  }

  table.co-students tbody tr td:nth-child(4):before {
    content: "Email";
  }

  table.co-students tbody tr td:nth-child(5):before {
    content: "Phone Number";
  }

  table.co-students tbody tr td:nth-child(6):before {
    content: "Level";
  }

  .column4,
  .column5,
  .column6 {
    text-align: left;
  }

  .column4,
  .column5,
  .column6,
  .column1,
  .column2,
  .column3 {
    width: 100%;
    padding: 0px;
  }

  tbody tr {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .container-table100 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
