* {
  font-family: "Open Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
}

/* a[title]:hover::before {
  content: attr(title);
  position: absolute;
  left: 55px;
  top: 20px;
  transform: translateY(-50%);
  background-color: #003898;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  z-index: 1000;
} */

.page {
  position: relative;
  min-height: 84vh;
}

.content-container {
  margin: 2rem 8rem;
}

.main-header {
  margin: 1rem 0 22px 0;
  text-align: center;
  font-size: 2.5rem;
  color: #003898;
}

.herokun {
  margin-top: 6rem;
}

.sub-header {
  text-align: center;
}

.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-text {
  font-size: 1.2rem;
}

.hero-text__bold {
  color: #003898;
}

.hero-image {
  height: 150px;
}

.hero-image2 {
  height: 250px;
}

.content-description {
  font-weight: 500;
  font-size: 1.13rem;
  line-height: 1.5;
}

.header {
  color: #b98d3b;
  text-align: center;
  font-size: 1.9rem;
}

.header__span {
  color: #003898;
}

.obj-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.obj {
  padding: 0 0 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
}

.obj-description {
  margin: 8px 20px 8px 8px;
  font-size: 1.13rem;
  line-height: 1.5;
}

.wrapper {
  max-width: 720px;
  margin: 50px auto;
  padding: 0 20px;
  position: relative;
}

.wrapper .center-line {
  position: absolute;
  height: 100%;
  width: 4px;
  background: #dfdede;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}

.wrapper .row {
  display: flex;
}

.wrapper .row-1 {
  justify-content: flex-start;
}

.wrapper .row-2 {
  justify-content: flex-end;
}

.wrapper .row section {
  background: #f1f0f0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
  margin: 0px -40px;
  position: relative;
}

.reg {
  text-decoration: none;
  color: #003898;
}

.reg-wk {
  margin-bottom: -5px;
  color: #003898;
}

.wrapper .row section::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #f1f0f0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  top: 28px;
  z-index: -1;
  transform: rotate(45deg);
}

.row-1 section::before {
  right: -7px;
}

.row-2 section::before {
  left: -7px;
}

.row section .icon,
.center-line .scroll-icon {
  position: absolute;
  background: #f7f7f7;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #003898;
  font-size: 24px;
  box-shadow: 0 0 0 4px #dfdede, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.center-line .scroll-icon {
  bottom: 0px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}

.row-1 section .icon {
  top: 15px;
  right: -60px;
}

.row-2 section .icon {
  top: 15px;
  left: -60px;
}

.row section .details,
.row section .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row section .details .title {
  font-size: 22px;
  font-weight: 600;
  color: #003898;
}

.row section p {
  margin: 10px 0 17px 0;
  font-size: 1.06rem;
  line-height: 1.5;
}

.footer--line {
  background-color: #e6e6e6;
  border: solid 1px #e6e6e6;
  margin: 20px 5% 0 5%;
}

footer {
  margin-top: 22px;
  text-align: center;
  color: #003898;
}

@media (max-width: 820px) {
  .obj {
    flex: 100%;
  }
}

@media (max-width: 750px) {
  .content-container {
    margin: 2rem;
  }

  .main-header {
    font-size: 2rem;
  }

  .header {
    font-size: 1.65rem;
  }

  .hero-image {
    height: 100px;
  }

  .hero-image2 {
    height: 200px;
  }

  .content-description,
  .obj-description,
  .row section p {
    font-size: 0.95rem;
  }

  .wrapper .center-line {
    left: 20px;
  }

  .wrapper .row {
    margin: 30px 20px 3px 60px;
  }

  .wrapper .row section {
    width: 100%;
  }

  .row-1 section {
    left: 40px;
  }

  .row-1 section::before {
    left: -7px;
  }

  .row-1 section .icon {
    left: -80px;
  }

  .row-2 section .icon {
    left: -80px;
  }
}

@media (max-width: 450px) {
  .page {
    min-height: 89=8vh;
  }
}

@media (max-width: 300px) {
  .wrapper .center-line,
  .row section::before,
  .row section .icon {
    display: none;
  }

  .row-1 section {
    left: 20px;
  }

  .row-2 section {
    left: -20px;
  }

  .wrapper .row {
    margin: 10px 0;
  }
}

@media (max-height: 690px) {
  .page {
    min-height: 88vh;
  }
}

@media (max-height: 650px) {
  .page {
    min-height: 100vh;
  }
}
