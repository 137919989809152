.page-wrapper {
  position: relative;
  overflow: visible;
  min-height: 100vh;
  width: calc(100% - 42px);
  top: 0;
  left: 42px;
  transition: all 0.4s ease-in;
}

.page-content {
  padding: 10px 10px 10px 22px;
}

.heading {
  text-align: center;
}

.profile-header {
  font-size: 1.7rem;
  color: #003898;
}

.dashboard-details {
  margin: 10px;
  display: flex;
  justify-content: center;
}

.details-half {
  margin-left: auto;
  margin-right: auto;
}

.details-heading {
  font-size: 1.17rem;
}
.details-heading2 {
  font-size: 1.7rem;
}

.details-content {
  position: relative;
  min-width: 25vw;
}

.details-content::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  background-color: #e7e7e7;
  width: 100%;
  height: 2px;
}

.details-title {
  color: #636363;
  font-size: 0.85rem;
}

.details-report {
  font-size: 1rem;
}

.details-name {
  font-size: 1rem;
  max-width: 25vw;
  overflow-wrap: break-word;
}

.capitalize {
  text-transform: capitalize;
}

.capitalize2::first-letter {
  text-transform: uppercase;
}

.details-name-link {
  text-decoration: none;
  color: #003898;
}

.work-form-wrapper {
  position: relative;
  margin: 20px;
  padding: 20px 20px 60px;
  display: grid;
  place-content: center;
}

.work-form {
  display: flex;
  align-items: baseline;
}

.work-form-half {
  display: grid;
  justify-items: start;
  align-items: center;
  padding: 20px 50px;
  min-height: 23rem;
}

.work-form-wrapper label {
  text-align: left;
  color: #636363;
  font-size: 0.85rem;
  display: flex;
  flex-direction: column;
}

.work-form-wrapper label:focus-within {
  color: #003898;
}

.label {
  padding-left: 12px;
}

.form-purpose {
  position: relative;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: inherit;
  margin: 15px 0 30px;
  border-radius: 10px;
  border: 2px solid #cecece;
  padding: 5px;
  background-color: #f7f7f7;
  outline: none;
}

.work-form-input,
.report-text {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #e7e7e7;
  margin: 10px;
  padding: 4px;
  width: 25vw;
  outline: none;
  font-size: 0.9rem;
  transition: 0.4s;
}

.work-form-input2 {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #d1d1d1;
  margin: 10px;
  padding: 4px;
  width: 5vw;
  outline: none;
  font-size: 0.9rem;
  transition: 0.4s;
}

.work-form-input.upload,
.work-form-input.upload:focus {
  border-bottom: none;
}

.edit-field:focus{
  border: none;
  outline: none;
}
.edit-field::placeholder{
  color: rgb(122, 112, 112);
}
.edit-field{
  width: 100%;
  border: none;
  background: none;
  color: black;
}
input[type="file"]::file-selector-button {
  border: 2px solid #003898;
  padding: 10px;
  border-radius: 15px;
  margin: 0 10px 0 0;
  background-color: #003898;
  color: #e7e7e7;
  cursor: pointer;
}

.work-form-input:focus,
.report-text:focus {
  border-bottom: 2px solid #003898;
}

.work-form-input2:focus {
  border-bottom: 2px solid #003898;
}

.work-form-input.caps {
  text-transform: uppercase;
}

.submit-upload {
  padding: 15px;
  margin: 15px 0;
  background-color: #003898;
  border: 1px solid #003898;
  border-radius: 15px;
  color: #f7f7f7;
  font-size: 1rem;
  cursor: pointer;
}

.export{
  margin-left: 55px;
  text-decoration: none;
  padding: 5px 10px;
  background-color: #003898;
  border: 1px solid #003898;
  border-radius: 5px;
  color: #f7f7f7;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  width: 70px;
  flex-direction: row;
}

.report-form,
.grid-small {
  display: grid;
  place-content: center;
}

.report-form1 {
  display: grid;
  place-items: center;
  margin-bottom: 50px;
}

.date-input {
  display: flex;
}

.report-text {
  width: auto;
  margin-left: 0;
  margin-bottom: 2rem;
}

.report-date {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #e7e7e7;
  outline: none;
  margin: 5px;
  font-size: 0.95rem; 
}

.report-date2 {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #e7e7e7;
  outline: none;
  font-size: 0.75rem; 
}

.report-form label {
  margin-top: 1ch;
  font-size: 0.9rem;
}

.supervisor-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.details-title.supervisor {
  margin-right: auto;
  text-align: center;
  /* font-size: .9rem; */
}

.details-title.supervisor-mobile {
  display: none;
}

.details-name.supervisor {
  color: #003898;
  font-size: 0.98rem;
}

.input-disabled{
  cursor: not-allowed;
}

.supervisor-input {
  margin: 5px;
  border-radius: 15px;
  border: 2px solid #cecece;
  padding: 8px;
  background-color: #f7f7f7;
  outline: none;
  transition: border 0.5s;
}

.select-supervisor {
  width: 180px;
  padding: 8px 5px;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #cecece;
}

.capitalize .noCapitalize{
text-transform: lowercase;
}

.batch-grade-input {
  width: 100px;
  padding: 8px 5px;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #cecece;
}

.batch-grade-input::-webkit-outer-spin-button,
.batch-grade-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.batch-grade-input {
  -moz-appearance: textfield;
}

.weekly-report-details {
  margin-left: 50px;
}

.weekly-report-row {
  display: flex;
}

.details-report{
  max-width: 88vw;
}

.grade-block{
  display: flex;
  flex-direction: row;
}

.weekly-report-row > p {
  margin-right: 20px;
}

.loaded {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.pagination{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 15px 0;
  width: fit-content;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}

.pagination-btn{
  padding: 10px 15px;
  margin: 5px 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;
}

.pagination-btn:hover,
.pagination-btn.active{
  background-color: #003898;
  color: #fff;
}

.search-box{
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-box span{
  margin: 0 10px;
  font-size: 1rem;
}

.search-box .login-form-input.search-input{
outline: none;
border: 2px solid #003898;
}

.disabled{
  cursor: not-allowed;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 900px) {
  .details-content {
    min-width: 60vw;
  }
}

.supervisor-input:focus {
  border: 2px solid #003898;
}

.submit-supervisor {
  background-color: #003898;
  border: 1px solid #003898;
  border-radius: 4px;
  color: #f7f7f7;
  padding: 5px;
  margin: 20px;
  min-width: 15vw;
  font-size: 0.9rem;
  cursor: pointer;
}

@media (max-width: 960px) {
  .dashboard-details {
    flex-direction: column;
  }
  .supervisor-section {
    flex-direction: column;
  }

  .details-title.supervisor {
    display: none;
  }

  .supervisor-input {
    min-width: 45vw;
  }

  .details-title.supervisor-mobile {
    display: block;
  }
}

@media (max-width: 960px) {
  .details-content {
    min-width: 60vw;
  }
  .details-name {
    max-width: unset;
  }
}

@media (max-width: 700px) {
  .details-half {
    margin: 0 5px 0;
  }

  .weekly-report-details {
    margin-left: 10px;
  }
}

@media (max-width: 650px) {
  .work-form {
    flex-direction: column;
  }

  .work-form-wrapper {
    margin: auto;
    padding: auto;
  }

  .work-form-half {
    padding: 0;
    margin: 0;
    min-height: unset;
  }

  .half-1 {
    padding-bottom: 3px;
  }

  .half-2 {
    padding-top: 3px;
  }

  .work-form-input {
    width: 70vw;
  }

  .supervisor-input {
    min-width: 55vw;
  }

  .grid-small {
    display: grid;
    place-content: center;
    place-items: unset;
  }
}

@media (max-width: 500px) {
  .details-content {
    min-width: 60vw;
  }

  .report-text {
    max-width: 70vw;
  }
}

@media (max-width: 350px) {
  .work-form-input {
    width: auto;
  }

  .report-text {
    max-width: 60vw;
  }
}

.export-btn {
  text-decoration: none;
  display: flex;
  align-items: center;
  width: fit-content;
}

.search-btn {
  padding: 15px;
  margin: 10px 0;
  color: #fff;
  border: none;
  background-color: #003898;
  border-radius: 15px;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 15px;
}
.edit-btn {
  padding: 15px 20px;
  margin: 10px 0;
  color: #fff;
  border: none;
  background-color: #003898;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 20px;
}

.pagination-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.filer{
  cursor:pointer;
  color:blue;
}